@import '@Styles/variables.module.scss';

.button {
  color: $colorP1;
  font-weight: 500;
  line-height: 24px;
  margin-right: 2px;
}

.icon {
  font-size: 24px;
  line-height: 24px;
}
