@import '@Styles/variables.module.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.outputContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  @media screen and ($screenXs) {
    flex-direction: column;
    gap: 20px;
  }
}
