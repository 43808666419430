@import '@Styles/variables.module.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background-color: $colorT5;
  max-width: 95%;
  max-height: 80%;
  outline: 0;
  border-radius: 4px;
  padding: 20px 20px 0;
  overflow: auto;
  position: relative;
}
