@import '@Styles/variables.module.scss';

.introText {
  font-size: 16px;
  color: $colorT2Lighter;
  margin-bottom: 32px;
  display: block;
  width: 100%;
  text-align: center;
  line-height: 24px;
}

.noFunds {
  font-size: 16px;
  color: $colorT2Lighter;
}

.headerButtons {
  display: flex;
  gap: 1em;
  @media screen and ($screenXs) {
    flex-direction: column;
  }
}
