@import '@Styles/variables.module.scss';

.section {
  display: flex;
  align-items: center;

  > div:first-child {
    flex: 1;
  }
}

.sectionTitle {
  margin: 0;
}

.sectionImage {
  max-height: 150px;
  margin-left: 50px;

  @media screen and ($screenXs) {
    max-height: 120px;
    margin-left: 12px;
  }
}

.readMoreLink.readMoreLink {
  &::after {
    content: '\25B8';
    margin-left: 8px;
  }
}
