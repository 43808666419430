@import '@Styles/variables.module.scss';

.actionName.actionName {
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.organizationName {
  margin: 4px 0 0;
  display: flex;
  align-items: center;
}

.organizationPageLink.organizationPageLink {
  margin-left: 4px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
