@import '@Styles/variables.module.scss';

.textWithIcon {
  display: flex;
  align-items: center;
  color: $colorT2Light;
  font-size: 12px;
}

.icon.icon {
  font-size: 18px;
  margin-right: 8px;
}

.value {
  margin-left: 4px;
  font-weight: 600;
}
