@import '@Styles/variables.module.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background-color: $colorT5;
  width: 860px;
  max-width: 95%;
  max-height: 80vh;
  outline: 0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 80px;
  position: relative;

  @media screen and ($screenXs) {
    padding: 20px 20px 10px;
  }
}

.header {
  text-align: center;
}

.title {
  font-size: 32px;
  margin: 0;
}

.subtitle {
  font-size: 16px;
  color: $colorT2Lighter;
  margin: 8px 0 0;
}

.content {
  margin: 30px 0 50px 0;
  text-align: center;
  overflow: auto;
  white-space: pre-wrap;
  width: 70%;
  line-height: 1.4;

  @media screen and ($screenXs) {
    width: 95%;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  width: fit-content;

  @media screen and ($screenXs) {
    flex-direction: column-reverse;
  }

  button {
    &:only-child {
      margin: 0 auto;
    }
  }
}
