@import '@Styles/variables.module.scss';

.filterContainer {
  display: flex;
  flex-direction: column;
}

.postcodeContainer {
  display: flex;
  gap: 10px;
}

.lockedMessage {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: $AFDustyPink100;
  padding-left: 10px;
}

.title {
  display: flex;
  gap: 5px;
  align-items: center;
  color: $AFDeepBlack;
}
