@import '@Styles/variables.module.scss';

.divider {
  margin: 40px 0;
}

.additionalServicesButtons.additionalServicesButtons {
  margin-top: 24px;
}

.termsBox {
  border: $tileBorder;
  border-radius: $tileBorderRadius;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;
  margin: 32px 0 24px;

  @media screen and ($screenXs) {
    align-items: stretch;
    flex-direction: column;
  }
}

.termsBoxHeader {
  margin: 0;

  @media screen and ($screenXs) {
    margin-bottom: 24px;
  }
}

.termsButtons {
  display: flex;
  flex-wrap: wrap;

  @media screen and ($screenXs) {
    flex-direction: column;
  }
}

.termsButton.termsButton {
  margin: 8px;
  box-sizing: border-box;
  height: 58px;
  padding: 16px 24px;

  @media screen and ($screenXs) {
    width: 100%;
    margin: 8px 0;
  }
}

.downloadButton.downloadButton {
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $AFWhite;
  background-color: $AFChalkyBlue500;
  transition: $trsFirst;

  &:hover {
    background-color: $AFChalkyBlue500Hover;
  }
}

.infoBox {
  position: relative;
  background-color: $colorT6;
  padding: 12px 32px;
  display: flex;
  align-items: center;
  margin-top: 32px;

  @media screen and ($screenXs) {
    padding: 12px;
  }
}

.messageImage {
  max-height: 80px;
}

.infoText {
  margin-left: 32px;
  max-width: 65%;
  line-height: 1.5;
  font-size: 14px;
}

.cloudImage {
  position: absolute;
  bottom: 0;
  right: 0;
}

.contactSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: $colorT2Light;
  margin-top: 60px;

  > * {
    margin: 8px 0;
  }
}
