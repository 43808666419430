@import '@Styles/variables.module.scss';

.date {
  color: $colorT3;
  font-size: 32px;
  font-weight: 600;
  line-height: 25px;
  margin-bottom: 6px;

  @media screen and ($screenXs) {
    display: none;
  }
}

.fullDate,
.month {
  background-color: $colorT3Light;
  border-radius: 24px;
  color: $colorT3;
  font-weight: 500;
  margin: 0;
  padding: 5px;
}

.month {
  font-size: 9px;
  line-height: 11px;

  @media screen and ($screenXs) {
    display: none;
  }
}

.fullDate {
  display: none;
  font-size: 12px;
  line-height: 15px;

  @media screen and ($screenXs) {
    display: block;
  }
}
