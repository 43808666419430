@import '@Styles/variables.module.scss';

.container {
  width: 100%;
  max-width: 300px;
}

.title {
  margin: 0;
}

.item {
  display: flex;
  justify-content: center;
}

.name {
  font-family: $fRoboto;
  margin: 0;
  text-align: center;
}

.rowContainer {
  display: flex;
  gap: 5px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.progressBar {
  width: 100%;
}

.helpIcon {
  width: fit-content;
  cursor: pointer;
  display: flex;
  align-self: center;
  font-size: 18px;
}

.tooltipContent {
  font-size: 12px;

  > p {
    margin: 0;
  }

  > p:first-child {
    font-weight: 600;
    margin-bottom: 10px;
  }
}

.userInfoContainer {
  margin-bottom: 20px !important;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}
