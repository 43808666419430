@import '@Styles/variables.module.scss';

.container {
  display: flex;
  flex-direction: column;
  @media screen and ($screenSm) {
    align-items: center;
  }
}
.title {
  font-size: 14px;
  font-weight: 700;
  line-height: 23px;
  margin-top: 0;
  color: $AFNavyBlue500;
  margin-bottom: 4px;
}

.subtitle {
  line-height: 23px;
  color: $AFNavyBlue500;
  font-size: 14px;
}

.form {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 12px;
  gap: 20px;

  @media screen and ($screenXs) {
    justify-content: center;
    flex-direction: column;
  }
}

.input {
  margin-bottom: 0 !important;
}

.input.input {
  input {
    z-index: 1;
  }
  fieldset {
    background-color: $colorT5;
    border-color: #50505d !important;
    border-radius: 10px;
  }
}
