@import '@Styles/variables.module.scss';

.container {
  background-color: $colorP1Light;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div:first-of-type {
    background: linear-gradient(0deg, $colorP1Light, $colorP1Light), rgba(255, 255, 255, 0.4) !important;
  }
}

.wideModal {
  max-width: 950px !important;
}

.modal {
  background-color: $colorT5;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  max-height: 85%;
  max-width: 486px;
  width: 100%;
}

.header {
  align-items: center;
  border-bottom: 1px solid $colorT2Paler;
  display: flex;
  padding: 12px;
}

.title {
  font-family: $fRoboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  margin: 0 auto 0 auto;
}

.content {
  overflow: auto;
  padding: 24px;
}
