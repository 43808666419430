@import '@Styles/variables.module.scss';

.header {
  background-color: $AFWhite;
  height: $navbarHeight;
  width: calc(100vw - $navWidth);
  margin-left: $navWidth;
  position: fixed;
  top: 0;
  z-index: $zIndexNavbar;
  border-bottom: 1px solid $AFSteel500;

  @media screen and ($screenMd) {
    padding: 0 20px;
  }

  @media screen and ($screenXs) {
    padding: 0 10px;
  }
}

.mobileHeader {
  margin: 0;
  width: 100vw;
}

.publicHeader {
  background-color: $AFNavyBlue500;
  width: 100vw;
  margin-left: 0;
  border-bottom: none;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: $contentWidthLg;
  max-width: 98%;
  margin: 0 auto;
  position: relative;
  z-index: $zIndexNavbarContent;
  justify-content: space-between;

  @media screen and ($screenMd) {
    max-width: 100%;
  }
  @media screen and ($screenSm) {
    justify-content: space-between;
  }
}

.contentRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;

  @media screen and ($screenSm) {
    width: auto;
  }
}

.linkWrapper {
  display: flex;
  align-items: center;
}

.marketingLinks {
  @media screen and ($screenSm) {
    display: none;
  }
}

.welcomeName {
  margin-left: 5px;
}
