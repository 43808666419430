@import '@Styles/variables.module.scss';

.container {
  margin: 40px auto;
  max-width: $contentWidthLg;

  @media screen and ($screenMd) {
    margin-top: 0;
  }
}

.banner {
  margin-bottom: 50px;
}

.cardsWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-evenly;
}

.card {
  margin: 12px 0 !important;
}

.buttonWrapper {
  text-align: center;
  margin-top: 32px;
}
