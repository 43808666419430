@import '@Styles/variables.module.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1em;
  min-height: 300px;
}

.quoteIcon {
  color: $colorP1Light;
  margin-bottom: -12px;
  margin-left: -12px;
  transform: rotate(180deg);
}

.clamp {
  line-clamp: 7;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.quote {
  margin: 0;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
}

.readMoreButton {
  text-decoration: underline;
  width: fit-content;
  align-self: center;
  font-size: 14px;
  color: $colorP1;
}
