@import '@Styles/variables.module.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background-color: $colorT5;
  width: 486px;
  max-width: 95vw;
  max-height: 95vh;
  outline: 0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: 24px 16px;
  position: relative;
}

.title {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 32px 0;
  text-align: center;
}

.closeButton.closeButton {
  background-color: transparent;
  padding: 16px;
  min-width: unset;
  top: 0;
  right: 0;
  position: absolute;
}

.socialsWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  max-width: 348px;
  width: 100%;
}

.singleSocial {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 6px;
}

.socialButton {
  align-items: center;
  background-color: $colorP1Light !important;
  border-radius: 50%;
  color: $colorP1 !important;
  display: flex;
  justify-content: center;
  height: 48px;
  margin-bottom: 4px;
  width: 48px;
}

.socialText {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}
