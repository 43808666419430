@use "sass:math";
@import '@Styles/variables.module.scss';

$avatarSize: 48px;
$avatarBorderWidth: 4px;

.container {
  position: relative;
  margin-bottom: (math.div($avatarSize, 2) + 8px);
  width: 100%;
}

.imageWrapper {
  position: relative;
}

.image {
  width: 100%;
  border-radius: 4px;
  object-fit: cover;

  &.projectImage {
    height: 140px;
  }
}

.videoWatermark.videoWatermark {
  color: rgba(255, 255, 255, 0.6);
  font-size: 48px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.imagePlaceholder {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 140px;
  background-color: $colorT3Light;
  color: $colorT3;
  border-radius: 4px;
}

.statusFlag {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 4px;
  padding: 8px 12px;
  font-size: 12px;

  &.fund {
    background-color: $colorT3Light;
    color: $colorT3;
  }

  &.project {
    background-color: $colorP2Light;
    color: $colorP2;
  }
}

.avatar.avatar {
  position: absolute;
  left: (-$avatarBorderWidth);
  bottom: math.div(-$avatarSize, 2);
  width: $avatarSize;
  height: $avatarSize;
  box-sizing: content-box;
  border: $avatarBorderWidth solid $colorT5;
}
