@import '@Styles/variables.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.title {
  font-size: 124px;
  margin: 0;
  color: $colorP2;
}

.subtitle {
  margin: 0 0 12px;
  font-size: 32px;
  color: $colorP1;
}

.text {
  margin: 0 0 42px;
}

.button {
  @media screen and ($screenXs) {
    width: 100%;
  }
}
