@import '@Styles/variables.module.scss';

.actionButtons {
  & > button:not(:last-child) {
    margin-right: 16px;

    @media screen and ($screenXs) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  @media screen and ($screenXs) {
    & > button {
      width: 100%;
    }
  }
}

.introText {
  font-size: 16px;
  color: $colorT2Light;
  margin-bottom: 32px;
  display: block;
  width: 100%;
  text-align: center;
  line-height: 24px;
}

.noProjects {
  font-size: 16px;
  color: $colorT2Light;
}
