@import '@Styles/variables.module.scss';

.container {
  margin: auto;
  box-sizing: border-box;
  padding: 80px 144px;
  max-width: $contentWidthLg;

  &.small {
    max-width: $contentWidthSm;
    padding-left: 32px;
    padding-right: 32px;
  }

  &.container {
    @media screen and ($screenMd) {
      padding: 40px 80px;
      max-width: 90%;
    }

    @media screen and ($screenSm) {
      padding: 20px 40px;
      max-width: 95%;
    }

    @media screen and ($screenXs) {
      padding: 0;
      max-width: unset;
    }
  }
}
