@import '@Styles/variables.module.scss';

.container {
  background-color: $colorT3Pale;
  border: 1px solid $colorT3;
  border-radius: 4px;
  display: flex;
  height: 100%;
  padding: 28px 24px;
  width: 100%;
}

.iconWrapper {
  align-self: center;
}

.title {
  color: $colorT3;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  line-height: 24px;
  margin-left: 16px;
}

.hint {
  color: $colorT2Light;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 4px;
  text-align: left;
}

.videoWrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  & > div {
    margin-bottom: 0;
  }
}

.closeVideoInput {
  align-self: flex-end;
  display: flex;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
  order: -1;
  text-transform: capitalize;

  span {
    display: block;
    margin-right: 8px;
  }
}

.inputHint {
  font-size: 12px;
  line-height: 18px;
  margin-top: 4px;
  text-align: left;
}
