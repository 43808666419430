@import '@Styles/variables.module.scss';

.container {
  padding: 8px;
  border: 1px solid $AFDanger;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: 8px;
  @media screen and ($screenXs) {
    font-size: 14px;
  }
}

.DangerChip {
  background-color: $AFDangerLight !important;
}

.DangerIcon {
  color: $AFDanger !important;
}

.criteriaContainer {
  display: flex;
  gap: 4px;
  align-items: center;
  flex-wrap: wrap;
}

.eligibilityHelpLink {
  margin-top: 8px;
  font-size: 14px;
  width: fit-content;
}
