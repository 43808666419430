@import '@Styles/variables.module.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 416px;
  text-align: center;
}

.header {
  margin: 0 0 16px;
  font-size: 32px;
  text-align: center;
}

.text {
  margin: 0 0 40px;
  font-size: 16px;
  text-align: center;
}

.doneButton {
  width: 100%;
}

.buttonsBar {
  display: flex;
  justify-content: center;
  margin-top: 8px;

  @media screen and ($screenXs) {
    flex-direction: column;
  }
}
