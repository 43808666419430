@import 'Styles/_variables.module.scss';

.container {
  border: $tileBorder;
  border-radius: $tileBorderRadius;
  padding: 16px;
  display: flex;
  align-items: center;

  @media screen and ($screenXs) {
    flex-direction: column;
  }
}

.organizationAvatar {
  flex-shrink: 0;
  margin-right: 24px;

  @media screen and ($screenXs) {
    margin-right: 0;
    margin-bottom: 24px;
  }
}

.thumbnail {
  width: 150px;
  height: 100px;
  margin-right: 24px;
  background-color: $colorT3Pale;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  flex-shrink: 0;

  > * {
    color: $colorT3;
  }

  @media screen and ($screenXs) {
    margin-right: 0;
    margin-bottom: 24px;
    width: 320px;
    max-width: 100%;
    height: 180px;
  }
}

.thumbnailTransparent {
  background-color: transparent;
}

.videoWatermark.videoWatermark {
  color: rgba(255, 255, 255, 0.6);
  font-size: 64px;

  @media screen and ($screenXs) {
    font-size: 84px;
  }
}

.midSection {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
}

.name.name {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 3px;

  @media screen and ($screenXs) {
    text-align: center;
    margin-bottom: 12px;
  }
}

.fundName {
  font-size: 14px;
  line-height: 16px;
  padding-top: 5px;
}

.textWithIconContainer {
  display: flex;
  flex-wrap: wrap;
  color: $colorT2Light;

  > div {
    margin: 3px 0;
    &:not(:last-child) {
      margin-right: 18px;

      @media screen and ($screenXs) {
        margin-right: 5px;
      }
    }

    @media screen and ($screenXs) {
      margin: 3px 5px;
    }
  }

  @media screen and ($screenXs) {
    justify-content: center;
  }
}

.textWithIcon {
  display: flex;
  align-items: center;

  > span {
    margin-left: 8px;
    font-size: 12px;
  }

  b {
    font-weight: 600;
  }
}

.buttonsSection {
  display: flex;
  align-items: center;
  margin-left: 24px;

  > *:first-child:not(:last-child) {
    margin-right: 24px;
  }

  @media screen and ($screenXs) {
    margin-left: 0;
    margin-top: 24px;
  }
}
