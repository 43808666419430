@import '@Styles/variables.module.scss';

.navItem {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 5px;
  padding: 5px;
}

.active {
  background-color: $AFDustyPink500;
  color: $AFNavyBlue500;
}
.active:hover {
  color: $AFWhiteKnight500;
}
