@import './variables.module.scss';
@import './fonts.module';

body {
  margin: 0;
  color: $colorT2;
  font-family: $fInter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  box-sizing: border-box;
}

button {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font-family: inherit;

  > img,
  > svg {
    vertical-align: middle;
  }
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $fRoboto;
  font-weight: 500;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;

  > div {
    height: 100%;
  }
}

div#nprogress {
  .bar,
  .spinner {
    z-index: $zIndexTopLoader;
  }
}

.sr-only {
  display: none;
}
