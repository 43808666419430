@import '@Styles/variables.module.scss';

.linkWrapper {
  border: 1px solid $colorT2Pale;
  border-radius: 4px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 24px;
  padding-left: 16px;
  height: 60px;
  position: relative;
}

.link {
  word-break: keep-all;
  white-space: nowrap;
  flex: 1;
  max-width: 400px;
  overflow: auto;
}

.copy {
  background-color: $colorT5;
  color: $colorT1;
  font-weight: 500;
  padding: 8px 12px;
  height: 100%;
}
