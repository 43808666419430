@import '@Styles/variables.module.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.textLeft {
  text-align: left;
}

.title {
  color: $colorT2;
  font-size: 60px;
  line-height: 79px;
  margin: 16px 0;

  @media screen and ($screenXs) {
    font-size: 44px;
    line-height: 58px;
  }
}

.subtitle {
  border-bottom: 1px solid $colorT2Paler;
  color: $colorT2Lighter;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  padding: 0 40px 56px;

  &.textLeft {
    padding-left: 0;
  }
}
