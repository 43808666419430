@import '@Styles/variables.module.scss';

.label {
  margin-bottom: 0;
  margin-top: 10px;
  text-align: left;
}

.checkboxTitle {
  margin: 15px 0;
}
