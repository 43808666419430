@import '@Styles/variables.module.scss';

.container {
  display: flex;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  width: 320px;
}

.contentWrapper {
  align-items: center;
  background-color: $colorT1Lighter;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-height: 424px;
  padding: 32px;
  position: relative;
  text-align: center;
  width: 100%;

  &::before,
  &::after {
    content: '';
    position: absolute;
  }

  &::before {
    background: url('/assets/CTACard/bottomArrows.svg');
    bottom: 4px;
    height: 80px;
    left: 4px;
    width: 80px;
  }

  &::after {
    background: url('/assets/CTACard/topArrow.svg');
    height: 38px;
    right: 4px;
    top: 4px;
    width: 38px;
  }
}

.cardTitle {
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 40px;
}
