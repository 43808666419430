@import '@Styles/variables.module.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 296px;
  padding: 40px 56px;
  position: relative;
  align-items: flex-start;
  height: 100%;

  @media screen and ($screenSm) {
    padding: 48px 32px;
  }
}

.container {
  display: flex;
  flex-direction: row-reverse;
  gap: 2em;
  height: 100%;

  @media screen and ($screenSm) {
    flex-direction: column;
    align-self: center;
  }
}

.responsesContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2em;

  @media screen and ($screenSm) {
    align-items: center;
  }
}

.tileBackground {
  height: auto;
  left: 0%;
  min-height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.title {
  font-size: 32px;
  font-weight: 700;
  margin: 0;
  position: relative;

  @media screen and ($screenMd) {
    font-size: 28px;
  }

  @media screen and ($screenXs) {
    text-align: center;
    font-size: 24px;
  }
}

.responsesCount {
  position: relative;
  line-height: 24px;
  margin: 0;
}
