@import '@Styles/variables.module.scss';

.container {
  height: 100%;
  min-height: 400px;
}

.iconWrapper {
  display: flex;
  gap: 12px;
  align-items: center;
}

.iconBackground {
  background-color: $colorT7;
  border-radius: 20%;
  padding: 8px;
  display: flex;
}

.heartIcon {
  color: $colorT3;
}

.starIcon {
  color: $colorP2;
}

.peopleIcon {
  color: $colorT1;
}

.iconText {
  font-weight: 300;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-family: $fRoboto;

  strong {
    font-size: 42px;
  }
}

.subTextContainer {
  font-size: 14px;
  display: flex;
  font-family: $fInter;
  align-items: center;
  gap: 0.2em;
  color: #817f7f;
}

.percentageNumber {
  color: green;
  font-weight: 600;
}

.percentageIcon {
  font-size: 20px;
  color: green;
}

.bold {
  font-weight: 600;
  color: #000;
}
