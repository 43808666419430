@import '@Styles/variables.module.scss';

.organizationTile {
  border: $tileBorder;
  border-radius: $tileBorderRadius;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.createdBy {
  font-size: 14px;
  color: $colorT2Lighter;
  display: block;
  margin-top: 24px;
}

.organizationName {
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  margin: 12px 0 8px;
}

.organizationType {
  font-size: 16px;
  margin-bottom: 8px;
}

.linksWrapper {
  margin: 16px 0 8px;
}
