@font-face {
  font-family: 'Roboto Slab';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url(../Fonts/RobotoSlab-Light.ttf) format('ttf'),
    url(../Fonts/RobotoSlab-Light.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto Slab';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url(../Fonts/RobotoSlab-Bold.ttf) format('ttf'),
    url(../Fonts/RobotoSlab-Bold.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto Slab';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url(../Fonts/RobotoSlab-Medium.ttf) format('ttf'),
    url(../Fonts/RobotoSlab-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url(../Fonts/Inter-Light.ttf) format('ttf'), url(../Fonts/Inter-Light.woff2) format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url(../Fonts/Inter-Bold.ttf) format('ttf'), url(../Fonts/Inter-Bold.woff2) format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url(../Fonts/Inter-Regular.ttf) format('ttf'),
    url(../Fonts/Inter-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'A2 Beckett WEB';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url(../Fonts/A2BeckettWEB-Medium.ttf) format('ttf'),
    url(../Fonts/A2BeckettWEB-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'Sofia Pro';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url(../Fonts/SofiaPro.ttf) format('ttf'), url(../Fonts/SofiaPro.woff2) format('woff2');
}

@font-face {
  font-family: 'Sofia Pro';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url(../Fonts/SofiaPro-Bold.ttf) format('ttf'),
    url(../Fonts/SofiaPro-Bold.woff2) format('woff2');
}
