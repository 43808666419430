@import '@Styles/variables.module.scss';

.errorSummary {
  align-items: center;
  color: $colorT4;
  display: flex;
  justify-content: center;

  span {
    margin-right: 0.25rem;
  }
}
