// Deprecated colors - used for some existing components, do not use for new components.
$colorP1: #00006b;
$colorP1Light: transparentize($colorP1, 0.92);
$colorP1Pale: transparentize($colorP1, 0.96);
$colorP2: #ff79ff;
$colorP2Light: #ffefff;
$colorP2Lighter: transparentize($colorP2, 0.84);
$colorP2Pale: transparentize($colorP2, 0.96);
$colorT1: #57ae74;
$colorT1Hover: darken($colorT1, 3%);
$colorT1Light: transparentize($colorT1, 0.8);
$colorT1LightHover: transparentize($colorT1, 0.7);
$colorT1Lighter: transparentize($colorT1, 0.9);
$colorT2: #000000;
$colorT2Light: transparentize($colorT2, 0.4);
$colorT2Lighter: transparentize($colorT2, 0.62);
$colorT2Pale: transparentize($colorT2, 0.8);
$colorT2Paler: transparentize($colorT2, 0.94);
$colorT3: #6e6eff;
$colorT3Mid: transparentize($colorT3, 0.5);
$colorT3Light: #e6e6fb;
$colorT3Pale: transparentize($colorT3, 0.94);
$colorT3Paler: #fdfdff;
$colorT4: #d23232;
$colorT4Light: transparentize($colorT4, 0.84);
$colorT5: #ffffff;
$colorT6: #f0f8ff;
$colorT7: #f2f2f2;

// New design colors - these should be used for new components and pages going forward.
// Primary Colours
$AFNavyBlue500: #00006b;
$AFNavyBlue400: #0a04a9;
$AFNavyBlue300: #a7b1ff;
$AFNavyBlue200: #e5e8ff;
$AFNavyBlue100: #f1f4ff;
$AFNavyBlue200Hover: darken($AFNavyBlue200, 10%);
$AFDustyPink500: #ff79ff;
$AFDustyPink400: #ffa7ff;
$AFDustyPink200: #fee7ff;
$AFDustyPink100: #fff3ff;

// Secondary Colours
$AFFuchsiaPink500: #b04ab0;
$AFChalkyBlue500: #5c5cff;
$AFChalkyBlue500Hover: darken($AFChalkyBlue500, 10%);
$AFFinnPurple500: #522552;
$AFFinnPurple400: #763177;

// Tertiary Colours
$AFSteel500: #d3d4e4;
$AFSteel400: #ebebf3;
$AFSteel400Hover: darken($AFSteel400, 10%);
$AFSteel300: #f6f6f9;
$AFGraphite300: #98989f;
$AFGraphite400: #50505d;
$AFGraphite500: #202031;
$AFWhiteKnight500: #fcfcfc;
$AFDeepBlack: #00001e;
$AFWhite: #ffffff;
$AFSuccess: #267544;
$AFSuccessLight: #daf1de;
$AFSuccessHover: darken($AFSuccess, 10%);
$AFWarningYellow500: #ab5703;
$AFWarningYellow100: #fff9eb;
$AFDanger: #c80838;
$AFDangerLight: #ffe2e5;
$AFDangerHover: darken($AFDanger, 10%);

// Project Status Colours
$ProjectDraftColor: $AFNavyBlue400;
$ProjectPublishedColor: $AFSuccess;
$ProjectFundedColor: $AFFuchsiaPink500;
$ProjectCompleteColor: $AFFinnPurple400;

// Fund Status Colours
$FundDraftColor: $AFNavyBlue400;
$FundAwaitingApprovalColor: $AFNavyBlue400;
$FundApprovedColor: $AFFuchsiaPink500;
$FundLiveColor: $AFSuccess;
$FundInDeliveryColor: $AFSuccess;
$FundClosedColor: $AFFinnPurple400;
$FundCompleteColor: $AFFinnPurple400;

$chartsThemeAF: $colorP1, $colorP2, $colorT1, $colorT3, $colorT4;
$chartsThemeReport: $colorP1, #1b5ab8, $colorP2, #bddab6, #9bd43d, #00b7c7, #ebdc78;
$chartsThemeStackedBar: #6e6eff, $colorP1, #b855b8, $colorP2, #522552, #57ae74;

// Content width
$contentWidthLg: 1280px;
$contentWidthSm: 736px;

// Breakpoints
$screenWidthLg: 1599px;
$screenWidthMd: 1279px;
$screenWidthSm: 991px;
$screenWidthXs: 599px;

$screenLg: 'max-width: ' + $screenWidthLg;
$screenMd: 'max-width: ' + $screenWidthMd;
$screenSm: 'max-width: ' + $screenWidthSm;
$screenXs: 'max-width: ' + $screenWidthXs;

// z-index values
$zIndexPasswordRules: 1;
$zIndexTimeline: 1;
$zIndexNavbarContent: 10;
$zIndexAvatar: 10;
$zIndexVerifyEmailOverlay: 1000;
$zIndexMatchesPreviewSubheader: 1190;
$zIndexNotificationPopup: 1199;
$zIndexNavbar: 1200;
$zIndexCtaButton: 2000;
$zIndexUpdateModal: 2050;
$zIndexShareModal: 2050;
$zIndexUpdateModalPopover: 2051;
$zIndexTopLoader: 2500;

// Other
$navbarHeight: 80px;
$trsFirst: 0.2s;
$notificationHeight: 70px;
$tileBorder: 1px solid $colorT2Pale;
$tileBorderRadius: 4px;
$tileShadow: 1px 3px 5px rgb(0 0 0 / 0.15);

$navWidth: 75px;
$navMargin: 20px;

// Font
$fRoboto: 'Roboto Slab', 'Arial';
$fInter: 'Inter', 'Arial';

// For use in js/ts files
:export {
  AFNavyBlue500: $AFNavyBlue500;
  AFNavyBlue400: $AFNavyBlue400;
  AFNavyBlue300: $AFNavyBlue300;
  AFNavyBlue200: $AFNavyBlue200;
  AFNavyBlue100: $AFNavyBlue100;
  AFNavyBlue200Hover: $AFNavyBlue200Hover;
  AFDustyPink500: $AFDustyPink500;
  AFDustyPink200: $AFDustyPink200;
  AFDustyPink100: $AFDustyPink100;
  AFSteel500: $AFSteel500;
  AFSteel400: $AFSteel400;
  AFSteel400Hover: $AFSteel400Hover;
  AFSteel300: $AFSteel300;
  AFWhiteKnight500: $AFWhiteKnight500;
  AFGraphite300: $AFGraphite300;
  AFGraphite400: $AFGraphite400;
  AFDeepBlack: $AFDeepBlack;
  AFChalkyBlue500: $AFChalkyBlue500;
  AFChalkyBlue500Hover: $AFChalkyBlue500Hover;
  AFFuchsiaPink500: $AFFuchsiaPink500;
  AFWhite: $AFWhite;
  AFSuccess: $AFSuccess;
  AFSuccessLight: $AFSuccessLight;
  AFSuccessHover: $AFSuccessHover;
  AFDanger: $AFDanger;
  AFDangerLight: $AFDangerLight;
  AFDangerHover: $AFDangerHover;
  AFFinnPurple500: $AFFinnPurple500;
  AFFinnPurple400: $AFFinnPurple400;
  ProjectDraftColor: $ProjectDraftColor;
  ProjectPublishedColor: $ProjectPublishedColor;
  ProjectFundedColor: $ProjectFundedColor;
  ProjectCompleteColor: $ProjectCompleteColor;
  colorP1: $colorP1;
  colorP1Pale: $colorP1Pale;
  colorP2: $colorP2;
  colorP2Light: $colorP2Light;
  colorP2Lighter: $colorP2Lighter;
  colorP2Pale: $colorP2Pale;
  colorT1: $colorT1;
  colorT1Light: $colorT1Light;
  colorT1LightHover: $colorT1LightHover;
  colorT1Lighter: $colorT1Lighter;
  colorT2: $colorT2;
  colorT2Light: $colorT2Light;
  colorT2Lighter: $colorT2Lighter;
  colorT2Pale: $colorT2Pale;
  colorT2Paler: $colorT2Paler;
  colorT3: $colorT3;
  colorT3Mid: $colorT3Mid;
  colorT3Light: $colorT3Light;
  colorT3Pale: $colorT3Pale;
  colorT4: $colorT4;
  colorT4Light: $colorT4Light;
  colorT5: $colorT5;
  screenWidthLg: $screenWidthLg;
  screenWidthMd: $screenWidthMd;
  screenWidthSm: $screenWidthSm;
  screenWidthXs: $screenWidthXs;
  screenMd: $screenMd;
  screenSm: $screenSm;
  screenXs: $screenXs;
  contentWidthLg: $contentWidthLg;
  contentWidthSm: $contentWidthSm;
  zIndexPasswordRules: $zIndexPasswordRules;
  zIndexTimeline: $zIndexTimeline;
  zIndexNavbarContent: $zIndexNavbarContent;
  zIndexAvatar: $zIndexAvatar;
  zIndexVerifyEmailOverlay: $zIndexVerifyEmailOverlay;
  zIndexMatchesPreviewSubheader: $zIndexMatchesPreviewSubheader;
  zIndexNotificationPopup: $zIndexNotificationPopup;
  zIndexNavbar: $zIndexNavbar;
  zIndexCtaButton: $zIndexCtaButton;
  zIndexUpdateModal: $zIndexUpdateModal;
  zIndexShareModal: $zIndexShareModal;
  zIndexUpdateModalPopover: $zIndexUpdateModalPopover;
  zIndexTopLoader: $zIndexTopLoader;
  chartsThemeAF: $chartsThemeAF;
  chartsThemeReport: $chartsThemeReport;
  chartsThemeStackedBar: $chartsThemeStackedBar;
  navWidth: $navWidth;
  navMargin: $navMargin;
}

// Animations
@keyframes loadingColorPulse {
  0% {
    color: $colorT2Light;
  }
  50% {
    color: $colorT2Lighter;
  }
  100% {
    color: $colorT2Light;
  }
}

$loadingColorPulseAnimation: loadingColorPulse 1s infinite;
