@import '@Styles/variables.module.scss';
.card {
  display: flex;
  width: 100%;
  gap: 10px;
  height: 200px;
  @media screen and ($screenXs) {
    height: fit-content;
    flex-direction: column;
    align-items: center;
  }
}

.link {
  &:hover,
  &:focus {
    color: $AFDeepBlack;
  }
}

.tile {
  padding: 5px;
  width: 100%;
}
.imageContainer {
  position: relative;
}
.image {
  border-radius: 15px;
  width: 245px;
  object-fit: cover;
  height: 100%;
}

.status {
  position: absolute;
  display: flex;
  align-items: center;
  transform: translateY(-100%);
  background-color: $AFWhiteKnight500;
  height: fit-content;
  border-radius: 0 10px 0 0;
  color: $AFFuchsiaPink500;
  p {
    margin: 0;
    padding: 5px;
    font-size: 12px;
  }
}

.videoPlayButton {
  position: absolute;
  left: 55px;
  top: 35px;
  color: $AFNavyBlue200;
  opacity: 0.7;
  @media screen and ($screenXs) {
    top: 5px;
  }
}

.projectInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px 0;
  max-width: calc(70%);

  h2 {
    height: 32px;
    overflow: hidden;
    margin: 0;
    width: fit-content;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
  h3,
  p {
    margin: 0;
  }
  @media screen and ($screenSm) {
    gap: 10px;
  }
}

.deliveryDetails {
  display: flex;
  gap: 20px;
}

.iconText {
  display: flex;
  gap: 2px;
  align-items: center;
}

.categoryList {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.chip {
  background-color: $AFSteel300 !important;
  color: $AFGraphite400 !important;
  width: fit-content;
}
