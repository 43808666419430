@import '@Styles/variables.module.scss';

$divider: 1px solid $colorT2Pale;

.loader {
  color: $colorT5;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  align-items: stretch;
  background-color: $colorT5;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  left: 50%;
  max-height: 95vh;
  max-width: 95vw;
  outline: 0;
  overflow: auto;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 24px;
  border-bottom: $divider;
  background-color: $colorT5;

  @media screen and ($screenXs) {
    padding: 12px;
    padding-right: 0;
  }
}

.avatar.avatar {
  height: 56px;
  width: 56px;
}

.headerTextWrapper {
  margin-left: 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  flex: 1;

  @media screen and ($screenXs) {
    margin-left: 8px;
  }
}

.headerText {
  font-size: 18px;
  display: flex;
  align-items: center;
  max-width: 100%;

  > span {
    white-space: nowrap;
    margin-right: 5px;
  }
}

.headerLink {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.headerSubtext {
  margin-top: 8px;
  font-size: 14px;
}

.actionButton.actionButton {
  background-color: transparent;
  min-width: unset;
  padding: 16px;
  position: absolute;
  right: 12px;
  top: 12px;
}

.mediaWrapper {
  padding: 16px 24px;
  background-color: $colorT2Paler;
  border-bottom: $divider;

  @media screen and ($screenXs) {
    padding: 20px;
  }
}

.videoWrapper {
  padding-bottom: 0;
}

.image {
  width: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.textWrapper {
  padding: 20px 20px 30px;

  @media screen and ($screenXs) {
    padding: 20px 15px 25px;
  }
}

.text {
  text-align: justify;
  margin: 0;
  white-space: pre-line;
}

.dateWrapper {
  color: $colorT2Light;
  margin-top: 18px;
  text-align: right;
  font-size: 14px;

  b {
    font-weight: 600;
  }
}
