@import '@Styles/variables.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  margin-top: 2.5em;

  @media screen and ($screenSm) {
    margin-top: 0;
  }
}

.stickiedContainer {
  position: fixed;
  top: 35%;
  right: 0;
  margin-right: 2em;
  padding: 24px;
  width: 14em;
  border: $tileBorder;
  border-radius: $tileBorderRadius;
  background-color: $colorT3Paler;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 15%);
  z-index: 10;

  @media screen and ($screenLg) {
    display: none;
  }
}

.responsesCount {
  font-size: 14px;
  margin: 0;
  text-align: center;
}
