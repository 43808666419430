@import '@Styles/variables.module.scss';

.mainFooter {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: auto;
  gap: 30px;
  padding: 2%;
}
.footerContentContainer {
  display: flex;
  gap: 5%;
  @media screen and ($screenSm) {
    flex-direction: column;
    gap: 30px;
  }
}

.logoContainer {
  display: flex;
  gap: 30px;
  @media screen and ($screenSm) {
    justify-content: center;
  }
}

.footer {
  background-color: #f1f4ff;
}

.logoSocialsContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  gap: 30px;
}

.certifiedLogo {
  display: block;
  margin-top: auto;
  height: 80px;
}

.linksColumn {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  @media screen and ($screenXs) {
    justify-content: space-evenly;
  }
}

.links {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  text-align: left;
  color: $AFNavyBlue500;

  @media screen and ($screenXs) {
    align-items: center;
    text-align: center;
  }
}

.linksColumnTitle {
  line-height: 23px;
  font-weight: 700;
  font-size: 14px;
  color: $AFNavyBlue500;
}

.link {
  line-height: 23px;
  color: $AFNavyBlue500;
  font-size: 14px;
}

.socials {
  color: $AFNavyBlue500;
  display: flex;
  width: 100%;
  gap: 20px;

  @media screen and ($screenSm) {
    justify-content: center;
  }
}

.socialIcon {
  font-size: 16px;
}

.copyright {
  color: $AFNavyBlue500;
  font-size: 12px;
  line-height: 24px;
  margin-top: 26px;
  margin-bottom: 0;
  opacity: 0.7;
  @media screen and ($screenXs) {
    text-align: center;
  }
}

.secondaryFooter {
  background: $AFNavyBlue400;
  padding: 2%;
}
.secondaryFooterContentContainer {
  display: flex;
  max-width: 1080px;
  margin: 0 auto;
  color: $AFNavyBlue200;
  @media screen and ($screenXs) {
    flex-direction: column;
  }
}

.demoContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 20px 30px;
  border-right: $AFDustyPink100 solid 1px;
  gap: 10px;
  @media screen and ($screenXs) {
    border-right: none;
    border-bottom: $AFDustyPink100 solid 1px;
  }
}

.signupContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 30px;
  gap: 10px;
}

.title {
  font-size: 1.952rem;
  margin: 0;
}

.btn {
  max-width: 200px;
}
