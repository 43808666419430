@import '@Styles/variables.module.scss';

.noTopMargin.noTopMargin {
  margin-top: 0;
}

.aboutItemHeader {
  font-size: 16px;
  margin-top: 32px;
}

.aboutItem {
  font-size: 14px;
  white-space: pre-line;
  word-break: break-word;
}

.membersSpacer {
  display: block;
  margin-top: 5px;
}

.iconTiles {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  @media screen and ($screenXs) {
    flex-direction: column;
    align-items: stretch;
  }
}
