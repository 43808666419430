@import '@Styles/variables.module.scss';

.container {
  border: $tileBorder;
  border-radius: $tileBorderRadius;
  width: 312px;
  padding: 24px;

  @media screen and ($screenSm) {
    width: 100%;
  }
  @media screen and ($screenXs) {
    padding: 18px;
  }
}

.button.button {
  width: 100%;
  background-color: $colorT1Light;
  color: $colorT1;
  margin-top: 16px;

  &:hover {
    background-color: $colorT1Light;
  }
}

.textWithIcon {
  margin-top: 18px;
  display: flex;
  align-items: center;

  > span {
    margin-left: 12px;
  }
}

.socialLinksHeader {
  text-align: center;
  margin: 24px 0 0;
}

.linksWrapper {
  margin-top: 12px;
  text-align: center;
}
