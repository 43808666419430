@import '@Styles/variables.module.scss';

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  color: $AFDeepBlack;
}

.userName {
  display: block;
}

.userEmail {
  display: block;
  font-size: 12px;
  margin-top: 5px;
}

.navContainer {
  display: flex;
  > li {
    color: #ffffff;
  }
}
