@import '@Styles/variables.module.scss';

.container {
  width: 100%;
  height: fit-content;
  background-color: $AFSteel300;
  border-radius: 6px;
  display: flex;
  justify-content: center;
}

.ctaButton {
  margin: 30px 0 !important;
}
