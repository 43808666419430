@import '@Styles/variables.module.scss';

$gutter: 24px;
$gutterSmall: 12px;
$gutterXs: 3px;

.masonryGrid {
  display: flex;
  flex-wrap: wrap;
  margin-left: (-$gutter);
  width: auto;
  max-width: 100%;

  @media screen and ($screenSm) {
    margin-left: (-$gutterSmall);
  }
  @media screen and ($screenXs) {
    margin-left: (-$gutterXs);
  }
}
.masonryGridColumn {
  padding-left: $gutter;
  background-clip: padding-box;

  @media screen and ($screenSm) {
    padding-left: $gutterSmall;
  }
  @media screen and ($screenXs) {
    padding-left: $gutterXs;
  }

  > * {
    margin-bottom: $gutter;
  }
}
