@import '@Styles/variables.module.scss';

.container {
  min-height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  @media screen and ($screenMd) {
    flex-direction: column;
  }
}

.imgWrapper {
  min-height: 100%;
  width: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and ($screenMd) {
    display: none;
  }
}

.childrenWrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 80px 20px 0 20px;
}

.logoWrapper {
  background-color: transparent;
  display: flex;
  align-items: center;
  left: 130px;
  pointer-events: none;
  position: absolute;
  top: 40px;
  height: 80px;

  @media screen and ($screenMd) {
    justify-content: center;
    left: 0;
    margin: 0 -20px;
    position: relative;
    top: 0;
    width: auto;
  }
}

.logo {
  pointer-events: all;
}

@media screen and ($screenMd) {
  .headerDark {
    background-color: $colorP1;
  }

  .headerLight {
    background-color: $colorP2;
  }
}
