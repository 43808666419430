@import '@Styles/variables.module.scss';

.highlightedInfo {
  background-color: $colorT3Pale;
  border: 1px solid $colorT3;
  border-radius: 4px;
  color: $colorT2;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 40px;
  margin-top: -14px;
  padding: 24px 46px 24px 24px;

  @media screen and ($screenXs) {
    padding: 24px 18px;
  }
}

.textBold {
  color: $colorP1;
  font-weight: 600;
}

.hyperlink {
  color: $colorT3;
  text-decoration: underline;
}

.tilesWrapper {
  display: flex;
  list-style-type: none;
  margin: 0 -16px 36px;
  padding: 0;

  @media screen and ($screenXs) {
    flex-direction: column;
    margin-bottom: 24px;
  }
}

.tile {
  flex-basis: 0%;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 0 16px;

  @media screen and ($screenXs) {
    margin-bottom: 24px;
  }

  &:first-of-type {
    .tileIcon {
      background-color: $colorP2Lighter;
      color: $colorP2;
    }

    &.tileActive {
      .tileInner {
        background-color: $colorP2Pale;
        border-color: $colorP2;
      }
    }
  }

  &:last-of-type {
    .tileIcon {
      background-color: $colorT3Light;
      color: $colorT3;
    }

    &.tileActive {
      .tileInner {
        background-color: $colorT3Pale;
        border-color: $colorT3;
      }
    }
  }
}

.tileInner {
  align-items: center;
  border: $tileBorder;
  border-radius: $tileBorderRadius;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 32px 48px;
  position: relative;
  text-align: center;
  transition: border-color 0.3s;
}

.tileIcon {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 64px;
  justify-content: center;
  margin-bottom: 16px;
  width: 64px;
}

.tileTitle {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin: 0 0 8px 0;
}

.tileText {
  color: $colorT2Lighter;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
}

.pitchNote {
  justify-content: center;
}
