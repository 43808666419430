@import '@Styles/variables.module.scss';

.progress {
  margin-top: 8px;
}

.textWithIconWrapper {
  margin: 24px 0 16px 0;

  > div:not(:last-child) {
    margin-bottom: 12px;
  }
}

.spacer {
  flex: 1;
}

.bottomLink.bottomLink {
  align-self: flex-start;

  &::after {
    content: '\25B8';
    margin-left: 8px;
  }
}
