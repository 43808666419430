@import '@Styles/variables.module.scss';

.introContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px;

  @media screen and ($screenXs) {
    padding: 40px 20px;
  }
}

.listContent {
  display: grid;
  gap: 20px 2%;
  grid-template-columns: 32% 32% 32%;
  padding-left: 0;
  padding-right: 0;

  @media screen and ($screenSm) {
    grid-template-columns: 49% 49%;
  }

  @media screen and ($screenXs) {
    grid-template-columns: 100%;
  }
}
