@import '@Styles/variables.module.scss';

.container {
  border: $tileBorder;
  border-radius: $tileBorderRadius;
  width: 312px;
  padding: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and ($screenSm) {
    width: 100%;
  }
  @media screen and ($screenXs) {
    padding: 18px;
  }
}

.header {
  color: $colorP1;
  font-weight: 500;
  font-size: 16px;
  margin: 0 0 16px;
}
