@import '@Styles/variables.module.scss';

.fieldNoteWrapper {
  align-items: center;
  display: flex;
  margin-bottom: 40px;
}

.fieldNoteIcon {
  align-items: center;
  background-color: $colorP2;
  border-radius: 50%;
  color: $colorT5;
  display: inline-flex;
  flex-shrink: 0;
  height: 24px;
  justify-content: center;
  margin-right: 12px;
  position: relative;
  width: 24px;

  svg {
    width: 100%;
  }
}

.fieldNote {
  color: $colorT2Light;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
}
