@import '@Styles/variables.module.scss';

.divider {
  border: none;
  border-top: 1px solid $colorT2Paler;
  margin: 40px 0;
}

.smallMargin {
  margin: 20px 0;
}
