@import '@Styles/variables.module.scss';

.avatar {
  width: 40px;
  height: 40px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.list {
  width: 100%;
}

.modal {
  background-color: $colorT5;
  width: 486px;
  max-width: 95vw;
  max-height: 95vh;
  outline: 0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: 24px 16px;
  position: relative;
}

.title {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 32px 0;
  text-align: center;
}

.closeButton.closeButton {
  background-color: transparent;
  padding: 16px;
  min-width: unset;
  top: 0;
  right: 0;
  position: absolute;
}

.copy {
  background-color: $colorT5;
  color: $colorT1;
  font-weight: 500;
  padding: 8px 12px;
  height: 100%;
}

.organisationName {
  color: black;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
