@import '@Styles/variables.module.scss';

$transitionTime: 0.3s;

.notification {
  height: $notificationHeight;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $colorT5;
  opacity: 0;
  position: fixed;
  top: $navbarHeight - $notificationHeight;
  transition: top $transitionTime ease-out, opacity 0s linear $transitionTime * 2;
  width: 100vw;
  z-index: $zIndexNotificationPopup;
  padding: 0 20px;
}

.visible {
  opacity: 1;
  top: $navbarHeight;

  transition: top $transitionTime ease-out, opacity 0s;
}

.success {
  background-color: $colorT1;
}

.error {
  background-color: $colorT4;
}

.contentWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: $contentWidthLg;
  overflow: auto;

  > *:first-child {
    margin-right: 10px;
  }
}

.message {
  display: block;
  overflow: auto;
  max-height: $notificationHeight - 10px;
  padding: 5px 0;
}
