@import '@Styles/variables.module.scss';

.tileContainer {
  border: 1px solid $AFSteel400;
  border-radius: 12px;
  display: flex;
  width: 100%;
  padding: 14px;
  background-color: $AFWhite;
  box-shadow: 0px 0px 5px 0px rgba(84, 84, 84, 0.05);
}
