@import '@Styles/variables.module.scss';

.title {
  font-size: 24px;
  font-weight: 500;
  line-height: 26px;
  margin: 12px 0 12px 0;
  opacity: 0.7;
}

.tileTitleWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.tileTitle {
  font-family: $fRoboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  margin: 0;
}

.organizationsList {
  list-style-type: none;
  padding: 0;
}

.listItem {
  align-items: center;
  display: flex;
  justify-content: space-between;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
}

.groupCount {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
}

.highlightedInfo {
  background-color: $colorT7;
  border: 1px solid $colorT3Pale;
  border-radius: 4px;
  color: $colorT2;
  font-size: 12px;
  line-height: 18px;
  margin: 40px auto;
  width: 100%;
  padding: 24px 46px 24px 24px;
  justify-content: center;

  @media screen and ($screenXs) {
    padding: 24px 18px;
  }
}
