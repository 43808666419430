@import '@Styles/variables.module.scss';

.figure {
  font-weight: 600;
  font-family: $fRoboto;
  font-size: 24px;
}

.text {
  font-weight: 400;
  font-weight: 16px;
}

.circle.circle {
  color: $colorT3;
  position: relative;
  z-index: 2;
}

.background {
  color: $colorT7;
  position: absolute;
  z-index: 1;
  svg {
    color: $colorT3Light;
  }
}
