@import '@Styles/variables.module.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background-color: $colorT5;
  width: 860px;
  max-width: 95%;
  max-height: 80vh;
  outline: 0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 80px 50px;
  position: relative;
  overflow: auto;

  @media screen and ($screenXs) {
    padding: 40px 20px 30px;
  }
}

.closeBtn.closeBtn {
  background-color: transparent;
  min-width: unset;
  position: absolute;
  right: 0;
  top: 0;
  @media screen and ($screenSm) {
    padding: 12px 20px;
  }
}

.header {
  font-size: 28px;
  margin: 0 0 24px;
  text-align: center;
}

.text {
  font-size: 16px;
  margin: 0 0 32px;
  line-height: 1.3;
  text-align: center;

  b {
    font-weight: 600;
  }

  hr {
    margin: 8px 0 0;
    border: none;
  }
}

.actionButtons {
  > button:not(:last-child) {
    margin-right: 18px;

    @media screen and ($screenXs) {
      margin-right: 0;
      margin-bottom: 12px;
    }
  }

  > button {
    @media screen and ($screenXs) {
      width: 100%;
      padding: 12px 18px;
    }
  }

  @media screen and ($screenXs) {
    align-self: stretch;
  }
}
