@import '@Styles/variables.module.scss';

$divider: 1px solid $colorT2Pale;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background-color: $colorT5;
  width: 800px;
  max-width: 95%;
  max-height: 90vh;
  outline: 0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;

  @media screen and ($screenXs) {
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
  }
}

.header {
  display: flex;
  align-items: center;
  margin: 24px;

  @media screen and ($screenXs) {
    margin: 12px;
  }
}

.avatar.avatar {
  height: 56px;
  width: 56px;
}

.headerTextWrapper {
  margin-left: 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;

  @media screen and ($screenXs) {
    margin-left: 8px;
  }
}

.headerTextMain {
  font-size: 18px;
  display: flex;
  align-items: center;

  > span {
    white-space: nowrap;
    margin-right: 8px;
  }

  @media screen and ($screenXs) {
    width: 100%;
  }
}

.select.select {
  min-width: 100px;

  & > div::before,
  & > div::after {
    display: none;
  }
}

.singleProjectName {
  font-weight: 500;
}

.headerSubtext {
  margin-top: 8px;
  font-size: 14px;
}

.headerLoadingText {
  color: $colorT2Light;
  font-style: italic;

  animation: $loadingColorPulseAnimation;
}

.content {
  text-align: center;

  @media screen and ($screenXs) {
    margin-bottom: 80px;
  }
}

.mediaWrapper {
  display: flex;
  padding: 16px 12px;
  background-color: $colorT2Paler;
  border-top: $divider;
  border-bottom: $divider;

  @media screen and ($screenXs) {
    flex-direction: column;
  }
}

.mediaChoice {
  padding: 0 12px;
  flex-basis: 0%;
  flex-grow: 1;
  flex-shrink: 0;

  @media screen and ($screenXs) {
    padding: 0;

    & + & {
      margin-top: 16px;
    }
  }
}

.textInput.textInput {
  width: 95%;
  margin-top: 20px;
}

.footer {
  padding: 8px 24px;
  border-top: $divider;
  display: flex;
  justify-content: flex-end;

  button {
    @media screen and ($screenXs) {
      padding: 12px 18px;
    }
  }

  button:not(:last-child) {
    margin-right: 10px;
  }

  @media screen and ($screenXs) {
    position: fixed;
    bottom: 0;
    width: 100%;
    justify-content: center;
    background-color: $colorT5;
  }
}
