// Having to nest so it overwrites Leaflet nesting like this
.holder {
  width: 200px;

  .by {
    margin: 0;
  }

  .link {
    margin: 0;
  }

  .name {
    font-weight: 900;
    margin: 0;
  }
}
