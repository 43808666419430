@import '@Styles/variables.module.scss';

.tileContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.2em;
}

.container {
  display: flex;
  gap: 10px;
  align-items: center;
  min-height: 70px;
}

.name {
  width: 200px;
  overflow-wrap: break-word;
}

.showMoreBtn {
  align-self: center;
}
