@import '@Styles/variables.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stepper {
  @media screen and ($screenXs) {
    width: 100%;
    overflow: auto;
  }
  background: none !important;
}

.content {
  max-width: $contentWidthLg;
  width: 100%;
}

.buttonsWrapper {
  align-items: center;
  border-top: 1px solid $colorT2Paler;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 56px;
  padding-top: 40px;

  @media screen and ($screenXs) {
    margin-top: 24px;

    button {
      margin-top: 16px;
    }
  }
}

.buttonsRow {
  display: flex;
  flex-wrap: wrap;

  button + button {
    margin-left: 16px;
  }
}
