@import '@Styles/variables.module.scss';

.container {
  background-color: $AFWhiteKnight500;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.noProfileContainer {
  display: flex;
  justify-content: center;
}

.navIcon {
  font-size: 2em;
}

.content {
  margin-top: $navbarHeight;
  flex-grow: 1;
  width: calc(100% - ($navWidth + $navMargin + 20px));
  margin-left: $navWidth + $navMargin;
  margin-right: 20px;
  @media screen and ($screenXs) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.publicContent {
  width: 100%;
  margin-left: 0;
}

.childrenWrapper {
  margin: 0 auto;
  padding: 20px 0;
  width: $contentWidthLg;
  max-width: 100%;
  position: relative;

  @media screen and ($screenXs) {
    padding: 10px 10px 0;
  }
}

.childrenWrapperNoTopPadding {
  padding-top: 0;
}

.footer {
  text-align: center;
  margin: 56px 0;
  color: $colorT2Lighter;
  font-size: 12px;

  @media screen and ($screenSm) {
    margin: 16px 0 64px 0;
  }
}
