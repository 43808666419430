@import '@Styles/variables.module.scss';

.count {
  font-family: $fRoboto;
  font-size: 54px;
  line-height: 79px;
  margin: 0;
}

.text {
  display: block;
  line-height: 24px;
  margin-bottom: 4px;
}

.avatar {
  align-items: center;
  background-color: $colorT3Light;
  border-radius: 50%;
  color: $colorT3;
  display: flex;
  height: 48px;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 48px;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.projectsListWrapper {
  margin: -8px;
}

.projectsRow {
  align-items: center;
  text-decoration: none;
  display: flex;
  padding: 8px;
  transition: background-color $trsFirst;

  &:hover {
    background-color: $colorT2Paler;
  }

  &:not(:first-of-type) {
    margin-top: 12px;
  }
}

.projectUrl {
  color: $colorT2;
  display: block;
  margin: 0 0 0 16px;
}
