@import '@Styles/variables.module.scss';

$avatarHorizontalPosition: 200px;
$headerImageOffsetSm: -80px;
$avatarHorizontalPositionSm: $avatarHorizontalPosition + $headerImageOffsetSm;
$headerImageOffsetXs: -125px;
$avatarHorizontalPositionXs: $avatarHorizontalPosition + $headerImageOffsetXs;

.headerImage {
  width: calc(100vw - $navWidth);
  min-height: 128px;
  background-repeat: no-repeat;
  background-color: $colorT3;
  background-size: contain;
  background-position: center bottom;
  position: relative;
  margin-left: -$navMargin;

  @media screen and ($screenLg) {
    background-position: left bottom;
    background-size: cover;
  }

  @media screen and ($screenSm) {
    background-position: left $headerImageOffsetSm bottom 0px;
  }

  @media screen and ($screenXs) {
    background-position: left $headerImageOffsetXs bottom 0px;
  }
}

.avatarWrapper.avatarWrapper {
  width: 150px;
  height: 150px;
  border: 1px solid $colorT3Light;
  position: absolute;
  top: 128px - 75px;
  left: calc(50% - 600px);

  @media screen and ($screenLg) {
    left: $avatarHorizontalPosition;
  }

  @media screen and ($screenSm) {
    left: $avatarHorizontalPositionSm;
  }

  @media screen and ($screenXs) {
    left: $avatarHorizontalPositionXs;
  }
}

.header {
  margin: 50px 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and ($screenXs) {
    margin-top: 70px;
    flex-direction: column;
    align-items: stretch;
  }
}

.profileName {
  margin: 0 10px 0 0;
  font-weight: 700;
  font-size: 60px;
  word-break: break-word;

  @media screen and ($screenSm) {
    font-size: 48px;
  }

  @media screen and ($screenXs) {
    font-size: 36px;
    margin-bottom: 18px;
  }
}

.profileButtonContainer {
  display: flex;
  gap: 5px;
}

.sectionHeader {
  font-weight: 500;
  font-size: 20px;
  margin: 48px 0 24px;
}

.columnsLayout {
  margin-top: 48px;
  display: flex;

  @media screen and ($screenSm) {
    flex-direction: column;
  }
}

.mainContent {
  flex: 1;
  min-width: 0;
}

.sideContent {
  margin-left: 64px;

  @media screen and ($screenMd) {
    margin-left: 48px;
  }
  @media screen and ($screenSm) {
    margin-left: 0;
    margin-top: 24px;
  }
}
