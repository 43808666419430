@import '@Styles/variables.module.scss';

.projectsContainer {
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-right: 5px;
}

.projectsContainer::-webkit-scrollbar {
  width: 0.5em;
  background-color: $AFWhiteKnight500;
}

.projectsContainer::-webkit-scrollbar-thumb {
  background-color: $AFSteel300;
  border-radius: 100px;
}

.header {
  margin: 0;
}

.subtitleContainer {
  display: flex;
  justify-content: space-between;
  > h3 {
    margin-top: 0;
  }
  @media screen and ($screenXs) {
    flex-direction: column;
    p {
      margin: 8px 0;
    }
  }
}

.filter {
  text-decoration: underline;
  font-weight: 600;
}

.subtitle {
  font-size: 14px;
  color: $AFGraphite400;
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 500;
  color: $AFDeepBlack;
}

.chip {
  background-color: $AFSteel300 !important;
  color: $AFGraphite400 !important;
}

.link {
  text-decoration: underline;
  color: $AFNavyBlue500;
}

.ctaTile {
  padding: 5px;
  width: 100%;
}

.ctaInfo {
  @media screen and ($screenXs) {
    text-align: center;
  }
}

.card {
  display: flex;
  width: 100%;
  gap: 20px;
  height: 200px;
  @media screen and ($screenXs) {
    height: fit-content;
    flex-direction: column;
    align-items: center;
  }
}
