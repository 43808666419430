@import '@Styles/variables.module.scss';

.hidden.hidden {
  display: none;
}

.textBold {
  font-weight: 600;
}

.previewWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.imagePreview {
  width: 90%;
  border-radius: 4px;
  object-fit: cover;

  @media screen and ($screenXs) {
    width: 100%;
  }
}

.deleteIcon {
  align-items: center;
  background-color: $colorT5;
  border-radius: 4px;
  box-shadow: 0 4px 4px $colorT2Paler;
  color: $colorT3;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  right: 10%;
  top: 24px;
  width: 40px;
}

.errorMessage {
  color: $colorT4;
  font-size: 12px;
}
