@import '@Styles/variables.module.scss';

.authorContainer {
  margin-top: auto;
}

.authorLayout {
  display: flex;
  gap: 10px;
  align-items: center;
}

.author {
  display: flex;
  flex-direction: column;
}

.avatar {
  align-items: center;
  background-color: $colorT3Light;
  border-radius: 50%;
  color: $colorT3;
  display: flex;
  height: 48px;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 48px;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}
