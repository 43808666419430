@import '@Styles/variables.module.scss';

.videoWrapper {
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  position: relative;
  width: 100%;
  margin-bottom: 24px;
}

.videoPlayer {
  position: absolute;
  top: 0;
  left: 0;
}

.deleteIcon {
  align-items: center;
  background-color: $colorT5;
  border-radius: 4px;
  box-shadow: 0 4px 4px $colorT2Paler;
  color: $colorT3;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  right: 24px;
  top: 16px;
  width: 40px;
}
