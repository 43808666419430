@import '@Styles/variables.module.scss';

.image {
  border-radius: 4px;
  width: 100%;
  object-fit: contain;
  max-height: 550px;
  background-color: $colorP1;
  margin: auto;
  overflow: hidden;
}
