@import '@Styles/variables.module.scss';

.container {
  border: $tileBorder;
  border-radius: $tileBorderRadius;
  padding: 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleContainer {
  display: flex;
  gap: 4px;
  align-items: center;
}

.helpIcon {
  width: fit-content;
  cursor: pointer;
  display: flex;
  align-self: center;
  font-size: 18px;
}

.date {
  margin: 0;
  font-weight: 600;
}
