@import '@Styles/variables.module.scss';

.container {
  border: 1px solid $colorT2Pale;
  border-radius: 4px;
  padding: 24px;
  width: 100%;
  transition: all ease-in 200ms;

  @media screen and ($screenSm) {
    padding: 12px 12px 18px;
  }

  &.hovered {
    border-color: $colorT2Lighter;
  }
}

.footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.engageHolder {
  align-items: center;
  display: flex;
}

.likeCounter {
  margin: 0 0 0 0.5rem;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  max-width: 100%;
}

.avatar.avatar {
  width: 48px;
  height: 48px;
}

.headerTextWrapper {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  overflow: hidden;
}

.more {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-left: 4px;
}

.projectName {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
  max-width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.headerSubtext {
  font-size: 14px;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  max-width: 100%;
}

.organizationName {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.space {
  margin-left: 4px;
}

.actionButton {
  margin-left: 18px;
  align-self: flex-start;

  @media screen and ($screenSm) {
    margin-left: 12px;
  }
}

.openModalButton {
  width: 100%;
}

.image {
  width: 100%;
  border-radius: 4px;
  margin-bottom: 16px;
  object-fit: cover;
}

.text {
  margin: 0;
  text-align: justify;
  font-size: 16px;
  white-space: pre-line;
  word-break: break-word;
}

.dateWrapper {
  color: $colorT2Light;
  text-align: right;
  font-size: 14px;

  b {
    font-weight: 600;
  }
}

.shareBarActive {
  padding-bottom: 0.5rem;
}
