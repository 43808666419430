@import '@Styles/variables.module.scss';

.tileTitleWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.tileTitle {
  font-family: $fRoboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  margin: 0;
}

.galleryWrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}

.mediaWrapper {
  border-radius: 4px;
  padding: 16px 8px 0;

  @media screen and ($screenXs) {
    width: calc(50% - 16px);
  }
}

.mediaWrapperVideo {
  max-width: 331px;
  width: 100%;

  @media screen and ($screenXs) {
    max-width: 100%;
  }

  & > div {
    margin-bottom: 0;
  }
}

.image {
  border-radius: 4px;
  height: 100%;
  max-height: 186px;
  width: auto;

  @media screen and ($screenXs) {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.tilesWrapper {
  display: flex;
  flex-wrap: wrap;
}

.imageTile {
  width: 100%;
}

.masonry {
  margin-left: -6px;
}

.masonryColumn {
  padding-left: 12px;

  & > div {
    margin-bottom: 12px;
  }
}

.noImages {
  color: $colorT2Light;
  font-style: italic;
}
