@import '@Styles/variables.module.scss';

.container {
  margin-bottom: 40px;
}

.hidden.hidden {
  display: none;
}

.textBold {
  font-weight: 600;
}

.previewWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.imagePreview {
  height: 80px;
  width: 80px;
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
}

.deleteIcon {
  align-items: center;
  background-color: $colorT5;
  border-radius: 4px;
  box-shadow: 0 4px 4px $colorT2Paler;
  color: $colorT3;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  right: 24px;
  top: 0;
  width: 40px;
}

.errorMessage {
  color: $colorT4;
  font-size: 12px;
}
