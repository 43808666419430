@import '@Styles/variables.module.scss';

.inputWrapper {
  align-items: center;
  background-color: $colorT3Pale;
  border: 1px solid $colorT3;
  border-radius: 4px;
  color: $colorT3;
  display: flex;
  padding: 28px 24px;
  transition: background-color $trsFirst, border-color $trsFirst;
  cursor: pointer;

  &.disabled {
    cursor: initial;
  }
}

.textWrapper {
  align-items: center;
  display: flex;
  margin-right: auto;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 500;
  line-height: 24px;
  margin-left: 16px;
}

.hint {
  color: $colorT2Light;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 4px;
  text-align: left;
}
