@import '@Styles/variables.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 250px;
}

.statement {
  font-size: 0.8em;
  margin-bottom: 10px;
}
