@import '@Styles/variables.module.scss';

.container {
  display: flex;
  width: 100%;
  align-items: center;

  @media screen and ($screenSm) {
    flex-direction: column;
  }
}

.thumbnail {
  width: 150px;
  height: 100px;
  margin-right: 24px;
  background-color: $colorT3Pale;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  flex-shrink: 0;

  > * {
    color: $colorT3;
  }

  @media screen and ($screenSm) {
    margin-right: 0;
    width: 320px;
    max-width: 100%;
    height: 180px;
  }
}

.videoWatermark.videoWatermark {
  color: rgba(255, 255, 255, 0.6);
  font-size: 64px;

  @media screen and ($screenSm) {
    font-size: 84px;
  }
}

.badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: $colorP2Light;
  color: $colorP2;
  padding: 8px;
  font-size: 12px;
  border-bottom-left-radius: 4px;
}

.infoSection {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-right: 24px;
  flex: 1;
  overflow: hidden;
  max-width: 100%;
  gap: 16px;

  @media screen and ($screenSm) {
    align-items: center;
    margin-right: 0;
    margin-bottom: 16px;
  }
}

.tileName {
  color: $colorP1;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and ($screenSm) {
    margin-top: 16px;
    text-align: center;
  }
}

.textWithIconContainer {
  display: flex;
  flex-wrap: wrap;

  > div {
    margin: 3px 0;
    &:not(:last-child) {
      margin-right: 18px;

      @media screen and ($screenSm) {
        margin-right: 5px;
      }
    }

    @media screen and ($screenSm) {
      margin: 3px 5px;
    }
  }

  @media screen and ($screenSm) {
    justify-content: center;
  }
}

.textWithIcon {
  display: flex;
  align-items: center;
  color: $colorP1;

  > span {
    margin-left: 8px;
    color: initial;
    font-size: 12px;
  }

  b {
    font-weight: 600;
  }
}

.buttonsSection.buttonsSection {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;

  > *:not(:last-child) {
    margin-right: 10px;

    @media screen and ($screenSm) {
      margin-right: 0;
    }
  }

  @media screen and ($screenSm) {
    flex-direction: column;
    align-self: stretch;

    > * {
      margin-bottom: 16px;
    }
  }
}

.deliveryPlanBtn {
  @media screen and ($screenSm) {
    order: 3;
  }
}

.deliveryPlanContainer {
  padding-left: 180px;

  @media screen and ($screenSm) {
    padding-left: 0;
  }
}

.divider {
  margin-top: 24px;
  margin-bottom: 0;
  border: none;
  border-top: 1px solid $colorT2Pale;
}

.deliveryPlanContent {
  display: flex;
  flex-direction: row;

  > div {
    flex: 1;
    &:first-child {
      margin-right: 32px;

      @media screen and ($screenSm) {
        margin-right: 0;
      }
    }
  }

  @media screen and ($screenSm) {
    flex-direction: column;
  }
}

.sectionHeader {
  font-family: inherit;
  color: $colorT2Lighter;
  font-weight: 400;
  font-size: 14px;
  margin: 28px 0 16px;
}

.budgetTableRow {
  > td:first-child {
    font-weight: 600;
    padding-right: 16px;
    vertical-align: top;
  }
  > td:not(:first-child) {
    padding-bottom: 10px;
    word-break: break-word;
  }
}

.voteCount {
  color: $colorT3;
  font-size: 1.2em;
  font-weight: bold;
  line-height: 0.5em;
}
