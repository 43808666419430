@import '@Styles/variables.module.scss';

$bannerHeight: 256px;

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $colorT3;
  color: $colorT5;
  padding: 0 140px;
  position: relative;
  box-sizing: border-box;
  height: $bannerHeight;

  @media screen and ($screenMd) {
    padding: 0 130px 0 100px;
  }

  @media screen and ($screenSm) {
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
    padding-left: 40px;
  }

  @media screen and ($screenXs) {
    padding: 10px;
    height: auto;
  }
}

.bigArrow {
  height: $bannerHeight;
  position: absolute;
  left: 64px;
  top: 0;
  width: $bannerHeight;

  @media screen and ($screenXs) {
    display: none;
  }
}

.smallArrows {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

.content {
  margin-right: 40px;
  max-width: 70%;
  z-index: 1;

  @media screen and ($screenSm) {
    margin-bottom: 24px;
    margin-right: 0;
    max-width: 75%;
  }

  @media screen and ($screenXs) {
    max-width: unset;
  }
}

.title {
  font-size: 60px;
  font-weight: 700;
  line-height: 1;
  margin: 0 0 12px 0;
  position: relative;

  @media screen and ($screenSm) {
    font-size: 32px;
    line-height: 42px;
  }
}

.text {
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  position: relative;

  @media screen and ($screenSm) {
    font-size: 14px;
    line-height: 20px;
  }
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;

  @media screen and ($screenSm) {
    flex-direction: row;
  }

  @media screen and ($screenXs) {
    flex-direction: column;
  }

  > button:not(:last-child) {
    margin-bottom: 18px;

    @media screen and ($screenSm) {
      margin-bottom: 0;
      margin-right: 18px;
    }

    @media screen and ($screenXs) {
      margin-bottom: 12px;
      margin-right: 0;
    }
  }
}

.linkButton.linkButton {
  color: $colorT5;
  background-color: $colorT1;
  flex-shrink: 0;
  z-index: 1;

  &:hover {
    background-color: $colorT1Hover;
  }

  @media screen and ($screenXs) {
    width: 100%;
  }

  &.secondary {
    color: $colorT2;
    background-color: $colorT5;

    &:hover {
      background-color: $colorT6;
    }
  }
}
