@import '@Styles/variables.module.scss';

.link {
  color: inherit;
  font-size: inherit;
  font-weight: 500;
  text-decoration: none;
  transition: color $trsFirst;
  cursor: pointer;

  &.primary {
    color: $colorP1;
  }

  &.secondary {
    color: $colorT1;
  }

  &:focus,
  &:hover {
    color: $colorP2;
  }

  &.disabled {
    cursor: not-allowed;
    color: $colorT2Light;
  }

  &.underline {
    text-decoration: underline;
  }
}
