@import '@Styles/variables.module.scss';

.container {
  border: $tileBorder;
  border-radius: $tileBorderRadius;
  box-shadow: $tileShadow;
  padding: 24px 18px 18px 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  gap: 10px;
  position: relative;
}

.statusBanner {
  width: fit-content;
  height: fit-content;
  background-color: red;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  padding: 6px;
  border-radius: 0 $tileBorderRadius;
  color: $colorP2;
  background-color: $colorP2Lighter;
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  gap: 10px;
}

.secondaryButtonContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.button {
  width: 100%;
}

.matchesChip.matchesChip {
  cursor: inherit;
  padding: 5px;
}

.name {
  font-size: 18px;
  font-weight: 600;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.amountWrapper {
  font-size: 14px;
}

.amount {
  font-weight: 600;
}
