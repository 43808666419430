@import '@Styles/variables.module.scss';

.container {
  height: auto;
}

.outputContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 250px;
  justify-content: space-between;
}

.itemContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: $fRoboto;
  gap: 5px;
  height: 100%;
  justify-content: center;
}

.number {
  font-size: 42px;
  margin: 0;
}

.description {
  font-size: 18px;
  margin: 0;
}

.clamp {
  line-clamp: 2;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.name {
  font-weight: 300;
  font-size: 14px;
  margin: 0;
  font-family: $fInter;
}

.readMoreButton {
  text-decoration: underline;
  width: fit-content;
  align-self: center;
  font-size: 12px;
  color: $colorP1;
  font-weight: 300;
  font-family: $fInter;
}
