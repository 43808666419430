@import '@Styles/variables.module.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.title {
  margin: 4px 0;
  font-size: 20px;
}

.meterContainer {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.meter {
  height: fit-content;
}

.showMoreBtn {
  width: fit-content;
  align-self: center;
}
