@import '@Styles/variables.module.scss';

.paginationControls {
  align-items: center;
  display: flex;
  font-family: $fInter;
  font-size: 14pt;
  flex-direction: horizontal;
  justify-content: space-between;
  margin-top: 1em;
  width: 100%;
}
