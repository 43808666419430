@import '@Styles/variables.module.scss';

.totalizersContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.values {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  gap: 10px;
  padding-right: 25px;
}

.projectMatchValue {
  font-weight: 500;
  display: flex;
  flex-direction: column;
  font-size: 30px;
  font-family: $fRoboto;

  strong {
    text-align: left;
    font-size: 42px;
  }
}

.underline {
  margin-top: 5px;
}

.textValue {
  display: flex;
  flex-direction: column;
  font-family: $fRoboto;
  white-space: pre-wrap;
  strong {
    font-size: 32px;
  }
}

.text {
  margin: 0;
}

.subtitle {
  font-size: 14px;
  color: $AFGraphite400;
}

.divider {
  margin: 20px 0;
}

.tabs {
  width: fit-content;
  border-top: none !important;
  color: $AFNavyBlue500;
  padding: 0 !important;
  button {
    color: $AFNavyBlue500;
    min-width: fit-content;
    padding: 0 18px;
  }
}
.title {
  margin: 0;
}
