@import '@Styles/variables.module.scss';

.amount {
  background-color: $AFGraphite300;
  min-width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.amountActive {
  background-color: $AFDustyPink500;
}
