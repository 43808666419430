@import '@Styles/variables.module.scss';

.container {
  border: $tileBorder;
  border-radius: $tileBorderRadius;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.statusFlag {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 $tileBorderRadius;
  padding: 8px 12px;
  font-size: 12px;
  background-color: $colorP2Light;
  color: $colorP2;
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  gap: 10px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.matchesChip.matchesChip {
  cursor: inherit;
}

.name {
  margin: 20px 0 8px;
  font-size: 18px;
  font-weight: 600;
}

.amountWrapper {
  font-size: 14px;
}

.amount {
  font-weight: 600;
}

.dateWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $colorT2Lighter;
  margin: 20px 0;
  font-size: 12px;
}

.dateText {
  margin-left: 10px;
}

.fundCtaButton {
  font-size: 14px;
}
