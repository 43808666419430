@import '@Styles/variables.module.scss';

.title {
  color: $colorT2;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  flex-direction: row;
  align-items: center;

  > svg {
    margin-right: 10px;
  }
}

.iconError {
  color: $colorT4;
}

.iconCheck {
  color: $colorT1;
}

.content {
  margin-bottom: 32px;
}

.subText {
  color: $colorT2Light;
  display: block;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 24px;
}

.hint {
  font-size: 14px;
  color: $colorT2Light;
  display: block;
  margin-top: 16px;

  > b {
    font-weight: 600;
  }
}
