@import '@Styles/variables.module.scss';

.header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0 40px;

  > *:not(:first-child) {
    margin: 10px 0;
  }
}

.headerInfo {
  display: flex;
  gap: 24px;
}

.headerInfoLikeCount {
  align-items: center;
  border: $tileBorder;
  border-radius: $tileBorderRadius;
  display: flex;
  justify-content: center;
  padding: 16px 24px;

  p {
    margin: 0 0 0 8px;
  }
}

.title {
  font-size: 60px;
  font-weight: 700;
  margin: 0 24px 0 0;
  word-break: break-word;

  @media screen and ($screenSm) {
    font-size: 48px;
  }

  @media screen and ($screenXs) {
    font-size: 38px;
  }
}

.content {
  display: flex;
  flex-direction: row;

  @media screen and ($screenMd) {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.mainContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 904px;

  @media screen and ($screenMd) {
    width: 100%;
  }
}

.tabsWrapper {
  margin-bottom: 48px;
}

.contentHeader {
  margin: 48px 0 24px;
  font-size: 20px;
}

.contentSubheader {
  font-size: 16px;
  margin-top: -20px;
}

.contentHeaderBorder {
  border: 0;
  border-top: 1px solid $colorT2Paler;
  margin-top: 48px;
}

.sectionHeader {
  font-size: 14px;
  margin: 32px 0 16px;
}

.pitchImage {
  max-width: 100%;
}

.textParagraph {
  margin: 0;
  font-size: 16px;
  word-break: break-word;
  white-space: pre-line;
}

.locationText {
  display: block;
  margin-bottom: 16px;
  font-size: 16px;

  > b {
    font-weight: 500;
  }
}

.budgetTable {
  tr {
    font-size: 16px;
    max-width: 50%;

    > td:first-child {
      word-break: break-word;
    }
  }

  td {
    padding-bottom: 12px;
  }
}

.budgetAmountCell {
  text-align: right;
  padding-left: 20px;

  > b {
    font-weight: 600;
  }
}

.masonry {
  align-self: stretch;
}

.projectsWrapper {
  max-width: 904px;
  width: 100%;

  @media screen and ($screenMd) {
    max-width: none;
  }
}

.projectsWrapperInner {
  margin: 0 -12px;
  width: calc(100% + 24px);
}

.cardWide.cardWide {
  margin: 0;
  max-width: 464px;
  width: 100%;

  @media screen and ($screenXs) {
    margin: 0 auto;
    max-width: 324px;
  }
}

.sideContent {
  width: 312px;
  margin-left: 64px;

  > div {
    margin-bottom: 24px;
  }

  @media screen and ($screenMd) {
    margin-left: 0;
    margin-bottom: 20px;
  }

  @media screen and ($screenXs) {
    width: 100%;
  }
}

.shareButton {
  margin-bottom: 24px;
  width: 100%;
}

.postAnUpdateButtonCompleted {
  margin-left: 1rem;

  button {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    margin: 0;
    position: relative;
    width: 100%;
  }
}

.iconTiles {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  @media screen and ($screenXs) {
    flex-direction: column;
    align-items: stretch;
  }
}

.eligibilityText {
  display: flex;
  align-items: center;
  gap: 4px;
  span {
    color: $AFDanger;
  }
}

.beneficiariesContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
