@import '@Styles/variables.module.scss';

.container {
  display: flex;
  align-items: center;

  > *:last-child {
    margin-left: 5px;
  }
}
