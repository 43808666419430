@import '@Styles/variables.module.scss';

.container {
  border: $tileBorder;
  border-radius: $tileBorderRadius;
  height: 72px;
  display: flex;
  align-items: center;
  padding: 10px 25px;
}

.text {
  margin-left: 14px;
}
