@import '@Styles/variables.module.scss';

.icon {
  border: $tileBorder;
  border-radius: $tileBorderRadius;
  height: 24px;
  width: 24px;
}

.checkedIcon {
  background-color: $colorP1;
  color: $colorT5;
  font-size: 16px;
}

.label {
  margin-bottom: 0;
  margin-top: 10px;
  text-align: left;
}
