@import '@Styles/variables.module.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 0 14px 0;
  text-align: left;
}

.wrapperCenter {
  flex-direction: column;
  text-align: center;
}

.sectionTitle {
  color: $colorT2;
  display: block;
  font-family: $fRoboto;
  margin: 0;
}

.sectionBigTitle {
  font-size: 38px;
  font-weight: 300;
  line-height: 50px;
  margin-bottom: 16px;
  text-align: center;
}

.sectionSmallTitle {
  font-size: 18px;
  line-height: 26px;
}

.sectionSubtitle {
  color: $colorT2Lighter;
  font-size: 14px;
  display: block;
  line-height: 20px;
  margin-bottom: 0;
  margin-top: 8px;

  &.sectionSubtitleBigHeader {
    text-align: center;
    margin-top: 0;
  }
}
