@import '@Styles/variables.module.scss';

.logoWrapper {
  display: flex;
  align-items: center;

  &.logoReverse {
    flex-direction: column;

    .logo {
      margin-bottom: 14px;
    }
  }
}

.logo {
  height: 32px;
  width: auto;
}

.logoText {
  margin-left: 7px;
  height: auto;
  width: 128px;

  &.hideMobile {
    @media screen and ($screenXs) {
      display: none;
    }
  }
}

.logoBig {
  svg {
    &:first-of-type {
      height: 45px;

      @media screen and ($screenXs) {
        height: 32px;
      }
    }

    &:last-of-type {
      width: 189px;

      @media screen and ($screenXs) {
        width: 128px;
      }
    }
  }
}
