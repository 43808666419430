@import '@Styles/variables.module.scss';

.tile {
  border: 1px solid $colorT2Pale;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 208px;
  padding: 24px;
  width: 100%;
  justify-content: center;

  @media screen and ($screenXs) {
    padding: 16px;
  }
}

.tileRow {
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.tileWrap {
  flex-wrap: wrap;
}

.title {
  margin: 0 0 10px 0;
  font-size: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
