@import '@Styles/variables.module.scss';

.container {
  background-color: $colorT5;
  box-shadow: 0 0 6px $colorT2Pale;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  margin-top: 12px;
  max-width: 352px;
  opacity: 0;
  padding: 24px 16px;
  position: absolute;
  text-align: left;
  top: 100%;
  transition: opacity $trsFirst, visibility $trsFirst;
  width: 100%;
  visibility: hidden;
  z-index: $zIndexPasswordRules;
}

.icon {
  color: $colorT5;
  position: absolute;
  left: 14px;
  top: -14px;
}

.title {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin: 0 0 8px;
}

.list {
  margin: 0;
  padding-inline-start: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  > li {
    width: 50%;
    margin: 4px 0;

    @media screen and ($screenXs) {
      width: 90%;
    }
  }

  > li::before {
    content: '\2022';
    color: $colorP1;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    width: 10px;
  }
}

.ruleFilled {
  text-decoration: line-through;
}
