@import '@Styles/variables.module.scss';

.publicButtonsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  :first-child {
    margin-left: 5px;
  }
}

.link {
  align-items: center;
  color: $colorT5;
  display: flex;
  font-size: 16px;
  line-height: 24px;
  padding: 12px;
  transition: $trsFirst;

  &:hover {
    color: $colorP2;
  }
}
