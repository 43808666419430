@import '@Styles/variables.module.scss';

.container {
  display: grid;
  gap: 20px 2%;
  grid-template-columns: 32% 32% 32%;

  @media screen and ($screenSm) {
    grid-template-columns: 49% 49%;
  }

  @media screen and ($screenXs) {
    grid-template-columns: 100%;
  }
}

.tile {
  border: $tileBorder;
  border-radius: $tileBorderRadius;
  padding: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}

.imageWrapper {
  margin-right: 20px;
  height: 64px;
  width: 72px;
  border-radius: 4px;
  background-position: center center;
  background-size: cover;
  flex-shrink: 0;
}

.text {
  flex: 1;
  overflow: auto;
  overflow-wrap: break-word;
  margin: 0;
}
