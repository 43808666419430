@import '@Styles/variables.module.scss';

.buttonContainer {
  display: flex;
  gap: 20px;
  @media screen and ($screenXs) {
    flex-direction: column;
    align-items: center;
  }
}
