@import '@Styles/variables.module.scss';

.tile {
  border: $tileBorder;
  border-radius: $tileBorderRadius;
  padding: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  flex: 1;

  &:not(:last-child) {
    margin-right: 24px;

    @media screen and ($screenXs) {
      margin-right: 0;
      margin-bottom: 24px;
    }
  }

  > b {
    font-family: $fRoboto;
    font-size: 20px;
    font-weight: 500;
  }
}

.iconWrapper {
  width: 48px;
  height: 48px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $colorP1Pale;
  color: $colorP1;
}

.tileText {
  display: flex;
  flex-direction: column;
  margin-left: 16px;

  > span {
    color: $colorT2Light;
    font-size: 12px;
  }

  > b {
    font-family: $fRoboto;
    font-weight: 500;
    font-size: 16px;
    margin-top: 6px;
  }
}
