@import '@Styles/variables.module.scss';

.wrapper {
  display: flex;
  flex-direction: column;
}

.container {
  background-color: $colorT5;
  outline: 0;
  border-radius: 4px;
  overflow: auto;
  position: relative;
}

.viewer {
  max-width: 100%;
}

.scaleButtons {
  bottom: 0;
  left: 20px;
  padding: 12px 0 20px;
  text-align: center;
  position: sticky;
}

.download {
  align-items: center;
  display: flex;
  font-size: 12px;
  margin-top: 6px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  a {
    color: inherit;
    display: block;
    margin-left: 8px;
    text-decoration: none;
  }
}
