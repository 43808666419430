.buttonHolder {
  display: flex;
  justify-content: flex-start;

  button {
    min-width: unset;
  }
}

.divider {
  margin: 0.5rem 0;
}

.holder {
  margin-top: 1rem;
}

.likeButtonHolder {
  align-items: center;
  display: flex;

  p {
    margin: 0 0 0 0.5rem;
  }
}

.summaryHolder {
  align-items: center;
  display: flex;
  justify-content: flex-start;

  p {
    font-size: 80%;
    margin: 0 0 0 0.5rem;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
