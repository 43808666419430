@import '@Styles/variables.module.scss';

.container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
  height: fit-content;
  justify-content: space-evenly;
  gap: 10px;

  &.tile {
    border: $tileBorder;
    border-radius: 4px 4px 0 0;
    padding: 24px;
    height: fit-content;
  }
}

.dateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  font-size: 16px;
  flex: 1;
}

.date {
  font-weight: 600;
}

.dateDistance {
  cursor: pointer;
  font-family: $fRoboto;
  font-weight: 500;
  font-size: 16px;

  &.tile {
    font-size: 20px;
  }
}

.progressBarWrapper {
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
}
