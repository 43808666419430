@import '@Styles/variables.module.scss';

.sectionSubheader {
  display: block;
  margin: 0 auto 8px;
  max-width: 415px;
  width: 100%;
}

.note {
  justify-content: center;
  margin-top: 32px;
}

.inputNote {
  text-align: start;
}

.questionWrapper {
  font-size: 14px;
  margin: 8px 0 32px;
}

.questionTitle {
  color: $colorT2;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-top: 32px;
}

.subText {
  color: $colorT2Light;
  display: block;
  line-height: 20px;
  margin-bottom: 24px;
}

.hint {
  font-size: 14px;
  color: $colorT2Light;
  display: block;
  line-height: 16px;
  margin-top: 16px;

  > b {
    font-weight: 600;
  }
}

.videoInputWrapper {
  border: $tileBorder;
  border-radius: $tileBorderRadius;
  padding: 8px 40px 0;
  margin-bottom: 24px;
  margin-top: 8px;
  text-align: center;
}

.tipsTable {
  display: flex;
  margin: 0 -12px;
  padding-bottom: 16px;

  @media screen and ($screenXs) {
    flex-direction: column;
    margin: 0;
  }
}

.tipsTableItem {
  flex-grow: 1;
  flex-basis: 0%;
  flex-shrink: 0;
  padding: 0 12px;

  @media screen and ($screenXs) {
    padding: 0;
    margin-bottom: 24px;
  }
}

.tipsTableElement {
  align-items: center;
  background-color: $colorT3Pale;
  border: 1px solid $colorT3;
  border-radius: 4px;
  display: flex;
  height: 100%;
  padding: 20px 22px;

  > div {
    margin: 0;
  }
}

.tipsTableText {
  color: $colorT2;

  strong {
    color: $colorT3;
    font-weight: 500;
  }
}
