@import 'Styles/_variables.module.scss';

.container {
  margin-top: 24px;
}

.actionListItem {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.header {
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 18px;
}

.noFunds {
  color: $colorT2Light;
  font-style: italic;
}
