@import '@Styles/variables.module.scss';

.budgetWrapper {
  padding-bottom: 24px;
}

.fieldset {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: relative;
  width: 100%;

  @media screen and ($screenXs) {
    flex-direction: column;
  }

  > *:first-child {
    flex-grow: 1;
    margin-right: 8px;
  }
  > *:nth-child(2) {
    margin-left: 8px;
    max-width: 160px;
    width: 100%;

    @media screen and ($screenXs) {
      margin-left: 0;
      margin-bottom: 56px;
      max-width: none;
    }
  }
}

.addBtnTotalWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;

  @media screen and ($screenXs) {
    flex-direction: row-reverse;
  }
}

.addButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $colorT2Light;
  font-size: 14px;

  > svg {
    margin-right: 10px;
  }
}

.total {
  font-size: 14px;
  color: $colorT2Lighter;
}

.totalField {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  border-radius: 4px;
  font-weight: 600;
  padding: 0 16px;
  overflow: auto;

  &Success {
    color: $colorT1;
    background-color: $colorT1Light;
  }
  &Error {
    color: $colorT4;
    background-color: $colorT4Light;
  }
}

.totalHelper {
  display: block;
  margin-top: 5px;
  font-size: 12px;
  color: $colorT2Lighter;
  text-align: center;
}
