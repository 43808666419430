@import '@Styles/variables.module.scss';

.formControl {
  display: none;
}

.typeBox {
  align-items: center;
  border: $tileBorder;
  border-radius: $tileBorderRadius;
  color: $colorT2;
  display: flex;
  font-size: 16px;
  line-height: 24px;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  transition: color $trsFirst, border $trsFirst;
  width: 100%;
}

.typeBoxError {
  border-color: $colorT4;
  color: $colorT4;
}

.typeBoxChecked {
  background-color: $colorP1Pale;
  border-color: $colorP1;
}

.typeBoxDisabled {
  background-color: $colorT2Paler;
  cursor: not-allowed;
}
