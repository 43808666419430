@import '@Styles/variables.module.scss';

.containerWrapper {
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  width: 320px;
}

.container {
  border: $tileBorder;
  border-radius: $tileBorderRadius;
  padding: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;

  .spacer {
    flex: 1;
  }
}
