@import '@Styles/variables.module.scss';

.chipLabel {
  display: flex;
  align-items: center;

  > span {
    margin-left: 10px;
    font-size: 16px;
  }
}
