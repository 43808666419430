@import '@Styles/variables.module.scss';

.container {
  border: $tileBorder;
  border-radius: $tileBorderRadius;
  padding: 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin: 0 0 18px;
  font-size: 20px;
  font-weight: 500;
}

.hashtagHeader {
  display: block;
  color: $colorT2Light;
  font-size: 14px;
  margin-bottom: 8px;
}

.hashtagChip {
  margin-bottom: 24px;
}
