@import '@Styles/variables.module.scss';

.form {
  margin-top: 40px;
}

.inline {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and ($screenXs) {
    flex-direction: column;
  }

  > div {
    width: 48%;

    @media screen and ($screenXs) {
      width: 100%;
    }
  }
}

.withMargin {
  margin-bottom: 24px;
}

.categoriesWrapper {
  margin-bottom: 40px;

  > div {
    margin-bottom: 18px;
  }
}
.sectionSubtitle {
  color: $colorT2Lighter;
  font-size: 14px;
  margin-bottom: 16px;
}
