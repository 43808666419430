@import '@Styles/variables.module.scss';

.menu {
  align-items: center;
  display: flex;
  justify-content: space-between;

  @media screen and ($screenSm) {
    background-color: $colorP1;
    display: flex;
    flex-direction: column;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 80px;
    padding-bottom: 24px;
    transition: opacity $trsFirst, visibility $trsFirst;
    visibility: hidden;
  }
}

.reversedDirection {
  justify-content: flex-end;
}

.menuOpen {
  opacity: 1;
  visibility: visible;
  @media screen and ($screenSm) {
    width: 100%;
  }
}

.link {
  align-items: center;
  color: $colorT5;
  display: flex;
  font-size: 16px;
  line-height: 24px;
  padding: 16px 20px;
  transition: $trsFirst;
  flex-shrink: 0;

  &:hover {
    color: $colorP2;
  }
}

.dropdownIcon {
  color: $colorP2;
  margin-left: 2px;
}

.buttonsWrapper {
  align-items: center;
  display: flex;

  @media screen and ($screenSm) {
    margin-top: 24px;
  }

  .loginButton {
    border-color: white;
    color: white;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 24px;
    padding-right: 24px;
    min-width: auto;
    margin-left: 20px;
  }
}

.btn {
  border-radius: 10px !important;
  background-color: $AFDustyPink500 !important;
  color: $AFNavyBlue500 !important;
  padding: 8px 24px !important;
  min-width: 0 !important;
}

.marketingLinks {
  display: none;
  @media screen and ($screenSm) {
    display: flex;
  }
}
